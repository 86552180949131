import React from "react";
import { Layout, Row, Col, Collapse } from "antd";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import Header from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import GuideSider from "../../components/guide/guide-sider";

import css from "../../styles/guide.css";

const Panel = Collapse.Panel;

class ProHelp extends React.Component {
  render() {
    return (
      <div>
        <Layout>
          <Helmet>
            <meta charSet="utf-8" />
            <meta
              name="description"
              content="DayViewer Planner Guide - Pro - This section shows what is in Pro and how to subscribe."
            />
            <title>DayViewer Online Planner Guide - Tips</title>
          </Helmet>
          <Header />
          <Layout style={{ minHeight: "100vh" }}>
            <GuideSider />
            <Layout className="guide-body">
              <h1>DayViewer Pro</h1>
              <Row>
                <Col>
                  <Collapse>
                    <Panel header="What is DayViewer Pro?" key="1">
                      <p>
                        DayViewer Pro is the full version of DayViewer and
                        unlocks all available features and is unrestricted in
                        terms of functionality.
                      </p>
                      <p>
                        Pro also unlocks a number of views and calendar entry
                        features not available in the Starter (Free) version.
                      </p>
                      <p>
                        See all features that are pro listed in the Plans page{" "}
                        <Link to="/plans"> here.</Link>{" "}
                      </p>
                      <p>
                        Upgrade to Pro by going into your Account > Membership
                        area. Alternatively click on your avatar in the header
                        and the drop down menu should show an "Upgrade" item.
                        Click this to go directly to your membership area.
                      </p>
                    </Panel>
                    <Panel
                      header="Key Differences between Starter &amp; Pro"
                      key="2"
                    >
                      <p>
                        DayViewer Starter plan is designed to help you get a
                        feel for DayViewer - you can use it for free but is
                        restricted by the historical data viewable (3 months
                        prior), number of entries / month (100) and many of the
                        views and features being unavailable.
                      </p>

                      <p>
                        DayViewer Pro is unrestricted and you can use all the
                        features therefore we say that it is the full version of
                        DayViewer.
                      </p>
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            </Layout>
          </Layout>
          <CommonFooter />
        </Layout>
      </div>
    );
  }
}

export default ProHelp;
